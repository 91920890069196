/* JS */

/* react */
import React from 'react';
/* third part components */

/* custom components */
import CaseStudyPage from './CaseStudyPage';

import './case-study.css';

var gsap;
var detect = require('is-client');
if (detect()) {
	gsap = require('gsap');
	let sctr = require('gsap/ScrollTrigger');
	gsap.gsap.registerPlugin(sctr);
}

const SushiiPage = props => {
	return (
		<CaseStudyPage
			title="Branding, Progettazione visiva, Progettazione web, Sviluppo web - Sushi-ii"
			relPath="sushi-ii"
			description="Il primo ristorante giapponese in Calabria con il kaiten sushi! Sushi-ii nasce per aprire a tutti le porte della vera e più gustosa cucina giapponese!"
		>
		</CaseStudyPage>
	);
};

export default SushiiPage;
